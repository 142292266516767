.reports-aside {
    background-color: #343a40;
    height: 100vh;
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    &__top {
        padding: .8125rem .5rem;
        border-bottom: 1px solid #4b545c;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        &-logo {
            margin-left: .8rem;
            margin-right: .5rem;
            max-width: 35px;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        }
        &-text {
            font-weight: 300;
            font-size: 1.25rem;
            padding-top: 8px;
        }
    }
    &__menu {
        list-style: none;
        margin: 0;
        padding: 15px 0 0;
        &-item {

            &-link, &-title {
                display: block;
                padding: .5rem 1rem;
                text-decoration: none;
                color: #ffffff;
            }
            &-title {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                font-weight: 700;
                &--icon {
                    max-width: 26px;
                    max-height: 26px;
                    width: auto;
                    height: auto;
                    margin-right: 8px;
                    &-wrap {
                        width: 26px;
                        height: 26px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                    }
                }
            }
        }
    }
    &__submenu {
        margin: 0;
        padding-left: 15px;
        &-item {
            &-link, &-title {
                display: block;
                padding: .5rem 1rem;
                text-decoration: none;
                color: #ffffff;
            }
        }
    }
}
