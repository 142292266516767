.data {
    display: flex;
    flex-wrap: wrap;
    &__form {
        width: 100%;
        max-width: 350px;
        &-custom {
            &-time {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                align-content: flex-end;
                justify-content: space-between;
            }
            &-hours,
            &-minutes {
                max-width: calc(50% - 5px);
            }
        }
    }
    &__summary {
        padding-left: 15px;
    }
}
