.reports {
    &-wrap {
        display: flex;
        flex-wrap: wrap;
        &__left {
            width: 100%;
            max-width: 250px;
        }
        &__right {
            width: 100%;
            max-width: calc(100% - 250px);
        }
    }
}
