.login-page {
    background-image: url("../images/kiosk_bg.png");
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &__error {
        color: #fff;
        background: #dc3545;
        border-color: #d32535;
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: .25rem;
    }
    &__container {
        text-align: center;
        width: calc(100% - 30px);
        max-width: 400px;
        border-radius: 10px;
        background-color: #f2f2f2;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25),0 10px 10px rgba(0,0,0,0.22);
        color: #333;
        &-header {
            font-weight: 700;
            background: transparent;
            text-transform: uppercase;
            font-size: 20px;
            letter-spacing: 1px;
            border-bottom: 1px solid rgba(0,0,0,0.125);
            padding: .75rem 1.25rem;
            position: relative;
        }
        &-logo {
            display: block;
            margin: 0 auto;
            max-width: 100px;
            height: auto;
        }
        &-footer {
            min-height: 74px;
            padding: .75rem 1.25rem;
            border-top: 1px solid rgba(0,0,0,0.125);
        }
    }
    &__form {
        padding: 1.25rem;
        max-width: 100%;
        width: 100%;
    }
    .login-page__input {
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24);
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:active,
        &:-webkit-autofill:focus {
            background-color: #FFFFFF !important;
            color: #555 !important;
            -webkit-box-shadow: 0 0 0 1000px white inset !important;
            -webkit-text-fill-color: #555555 !important;
            transition: background-color 5000s ease-in-out 0s;
            filter: grayscale(100%) brightness(110%);
        }
        &-label {
            text-align: left;
            display: block;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: .5rem;
        }
    }
    .login-page__submit {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
        width: 100%;
        color: #fff;
        font-weight: 700;
        max-width: 300px;
        background-color: #D41C18;
        margin-bottom: 16px;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.2);
        -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23);
        margin-top: 1rem;
    }
}
