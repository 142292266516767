.btn {
    height: 35px;
    padding: 0 30px;
    margin: 0 0 30px;
    display: block;
    background-color: $color-font-main;
    color: $color-bg-main;
    border: 2px solid $color-font-main;
    border-radius: 5px;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1em;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-decoration: none;
    &--border {
        background-color: $color-bg-main;
        color: $color-font-main;
        border-color: $color-font-main;
    }
}
.return-wrap {
    display: flex;
    flex-wrap: wrap;
}
.mb-3 {
    margin-bottom: 30px;
}
.mb-45 {
    margin-bottom: 45px;
}
