* {
    box-sizing: border-box;
}
html, body {
    background-color: $color-bg-main;
    color: $color-font-main;
    font-family: $font-main;
    margin: 0;
    padding: 0;
}
ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}
p {
    margin: 0;
    padding: 0;
}
body {
    .admin {
        display: none;
    }
    .admin-task-list {
        .admin {
            display: block;
        }
    }
}
table {
    border-collapse: collapse;
}
