.custom-select {
    position: relative;
    margin-bottom: 10px;
    &__wrap {
        position: relative;
        z-index: 9;
    }
    &__list {
        position: absolute;
        top: 100%;
        height: 0;
        overflow: hidden;
        width: 100%;
        z-index: 6;
        &-item {
            padding: 5px;
            cursor: pointer;
            &:hover {
                background-color: rgba(255,255,255,0.25);
            }
            &:first-child {
                margin-top: 5px;
            }
            &:last-child {
                margin-bottom: 15px;
            }
        }
    }
    &__input {
        input {
            margin-bottom: 0;
        }
    }
    &__select {
        display: none;
    }
    &.open {
        .custom-select {
            &__input {
                position: relative;
                z-index: 9;
                input {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &__list {
                height: auto;
                max-height: 250px;
                overflow: auto;
                background-color: $color-bg-main;
                border: 2px solid $color-font-main;
                border-top: none;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 0 0px 20px rgba(0,0,0,1);

            }
            &__close {
                display: block;
            }
            &__toggle {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__toggle {
        position: absolute;
        top: 0;
        right: 0;
        height: 35px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 35px;
        padding: 10px;
        img {
            display: block;
            max-width: 100%;
            height: auto;

        }
    }
    &__close {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 3;
    }
}
