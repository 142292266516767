* {
  box-sizing: border-box;
}

html, body {
  background-color: #1a1b1c;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

body .admin {
  display: none;
}
body .admin-task-list .admin {
  display: block;
}

table {
  border-collapse: collapse;
}

.btn {
  height: 35px;
  padding: 0 30px;
  margin: 0 0 30px;
  display: block;
  background-color: #ffffff;
  color: #1a1b1c;
  border: 2px solid #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
}
.btn--border {
  background-color: #1a1b1c;
  color: #ffffff;
  border-color: #ffffff;
}

.return-wrap {
  display: flex;
  flex-wrap: wrap;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-45 {
  margin-bottom: 45px;
}

.login-page {
  background-image: url("../images/kiosk_bg.png");
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-page__error {
  color: #fff;
  background: #dc3545;
  border-color: #d32535;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.login-page__container {
  text-align: center;
  width: calc(100% - 30px);
  max-width: 400px;
  border-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: #333;
}
.login-page__container-header {
  font-weight: 700;
  background: transparent;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
}
.login-page__container-logo {
  display: block;
  margin: 0 auto;
  max-width: 100px;
  height: auto;
}
.login-page__container-footer {
  min-height: 74px;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.login-page__form {
  padding: 1.25rem;
  max-width: 100%;
  width: 100%;
}
.login-page .login-page__input {
  width: 100%;
  max-width: 100%;
  margin-bottom: 16px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.login-page .login-page__input:-webkit-autofill, .login-page .login-page__input:-webkit-autofill:hover, .login-page .login-page__input:-webkit-autofill:active, .login-page .login-page__input:-webkit-autofill:focus {
  background-color: #FFFFFF !important;
  color: #555 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #555555 !important;
  transition: background-color 5000s ease-in-out 0s;
  filter: grayscale(100%) brightness(110%);
}
.login-page .login-page__input-label {
  text-align: left;
  display: block;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0.5rem;
}
.login-page .login-page__submit {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  width: 100%;
  color: #fff;
  font-weight: 700;
  max-width: 300px;
  background-color: #D41C18;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 1rem;
}

form {
  max-width: 350px;
}
form label {
  display: block;
  margin-bottom: 5px;
}
form select, form input {
  margin-bottom: 10px;
  height: 35px;
  font-size: 15px;
}
form textarea {
  resize: none;
  width: 100%;
  min-height: 300px;
  max-width: 100%;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background-color: #1a1b1c;
  color: #ffffff;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 15px;
  font-family: Arial, sans-serif;
}
form textarea:focus, form textarea:active {
  outline: none;
}
form input[type=text], form input[type=email], form input[type=password] {
  background-color: #1a1b1c;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 0 5px;
  width: 100%;
  filter: grayscale(100%) brightness(110%);
  font-size: 15px;
}
form select {
  width: 100%;
  background-color: #1a1b1c;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 5px;
}
form select.roles {
  min-height: 75px;
}
form input[type=submit], form button[type=submit] {
  height: 35px;
  padding: 0 30px;
  margin-left: auto;
  margin-right: 0;
  display: block;
  background-color: #ffffff;
  color: #1a1b1c;
  border: 2px solid #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  cursor: pointer;
}
form .form-date {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
form .form-date select {
  max-width: calc(33.3333333333% - 5px);
}

.list a {
  color: inherit;
  text-decoration: none;
}
.list .freeDay {
  background-color: rgba(0, 255, 0, 0.3);
}
.list .empty {
  background-color: rgba(255, 0, 0, 0.3);
}
.list .today {
  background-color: rgba(0, 0, 255, 0.3);
}

.summary-filters .service,
.summary-filters .project,
.summary-filters .type {
  display: none;
}

.summary-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  align-items: flex-start;
}
.summary-list__table {
  margin: 0 15px 30px;
  width: 100%;
  max-width: calc(20% - 30px);
}
form[name=filter] {
  max-width: 100%;
  margin-bottom: 30px;
}
form[name=filter] #filter {
  display: flex;
  flex-wrap: wrap;
}
form[name=filter] #filter > div {
  width: auto;
  margin-right: 20px;
}
form[name=filter] #filter > div:last-child {
  margin-right: 0;
}
form[name=filter] select {
  margin: 0;
}
form[name=filter] button[type=submit] {
  margin-top: 23px;
}

form[name=filter] .form-date__from, form[name=filter] .form-date__to {
  display: flex;
}
form[name=filter] .form-date__from select, form[name=filter] .form-date__to select {
  width: auto;
  margin-right: 5px;
}
form[name=filter] .form-date__from select:last-child, form[name=filter] .form-date__to select:last-child {
  margin-right: 0;
}

.report {
  overflow: auto;
}
.report .separator:last-child {
  display: none;
}
.report tr:nth-child(2n) {
  background-color: rgba(255, 255, 255, 0.05);
}
.report tr td {
  white-space: nowrap;
  font-size: 15px;
}
.report tr th {
  background-color: rgba(0, 0, 255, 0.3);
}
.report tr .service-name {
  background-color: rgba(0, 255, 0, 0.3);
}
.report .tfoot * {
  background-color: rgba(255, 0, 0, 0.3);
}
.report .filters-report {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
}
.report .filters-report .filter {
  display: none;
}
.report .filters-report [name=filter] {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
}
.report .filters-report [name=filter] > div {
  display: none;
}
.report .filters-report [name=filter] .filter--month__select-wrap,
.report .filters-report [name=filter] .submit {
  display: block;
}
.report .filters-report [name=filter] .submit {
  margin: 0 15px 0 10px;
}
.report .filters-report [name=filter] .submit button[type=submit] {
  font-size: 0;
}
.report .filters-report [name=filter] .submit button[type=submit]:after {
  content: "Generuj raport";
  font-size: 16px;
  letter-spacing: 0.1em;
}
.report .filter--month {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-right: 20px;
  max-width: none;
  align-items: flex-end;
  align-content: flex-end;
}
.report .filter--month__select {
  margin: 0;
}
.report .filter--month__select-wrap {
  margin-right: 5px;
}
.report .filter--month__btn-wrap .btn {
  margin: 0 0 0 15px;
  font-size: 16px;
}
.report-project form[name=filter] {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
}
.report-project__header {
  margin-top: 0;
  margin-bottom: 10px;
}
.report-project__header-subprojects {
  margin-bottom: 10px;
}
.report-project__submit, .report-project__submit button[type=submit] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.report-project__date {
  margin-bottom: 30px;
}
.report-project__select {
  margin-right: 15px;
}
@media print {
  html, body {
    background: #ffffff !important;
    color: #000000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari, Edge */
    color-adjust: exact !important;
    /*Firefox*/
  }

  .report {
    overflow: visible !important;
  }

  .reports-wrap__left {
    width: 0 !important;
    display: none !important;
  }
  .reports-wrap__left * {
    display: none !important;
  }

  .reports-header {
    width: 0 !important;
    display: none !important;
  }
  .reports-header * {
    display: none !important;
  }

  .filters-report {
    display: none !important;
  }

  .reports-wrap__right {
    background-color: #ffffff !important;
    width: 100% !important;
    max-width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
  }
  .reports-wrap__right table, .reports-wrap__right tbody, .reports-wrap__right tr, .reports-wrap__right td, .reports-wrap__right th {
    border-collapse: separate !important;
    border-color: #000000 !important;
  }

  @page {
    margin: 5px !important;
  }
  .main {
    padding: 0 !important;
    margin: 0 !important;
  }

  .data__list--report {
    max-width: 100% !important;
  }

  table {
    page-break-inside: avoid;
    page-break-after: always;
    page-break-before: always;
  }
}
.data {
  display: flex;
  flex-wrap: wrap;
}
.data__form {
  width: 100%;
  max-width: 350px;
}
.data__form-custom-time {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: space-between;
}
.data__form-custom-hours, .data__form-custom-minutes {
  max-width: calc(50% - 5px);
}
.data__summary {
  padding-left: 15px;
}

.custom-select {
  position: relative;
  margin-bottom: 10px;
}
.custom-select__wrap {
  position: relative;
  z-index: 9;
}
.custom-select__list {
  position: absolute;
  top: 100%;
  height: 0;
  overflow: hidden;
  width: 100%;
  z-index: 6;
}
.custom-select__list-item {
  padding: 5px;
  cursor: pointer;
}
.custom-select__list-item:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.custom-select__list-item:first-child {
  margin-top: 5px;
}
.custom-select__list-item:last-child {
  margin-bottom: 15px;
}
.custom-select__input input {
  margin-bottom: 0;
}
.custom-select__select {
  display: none;
}
.custom-select.open .custom-select__input {
  position: relative;
  z-index: 9;
}
.custom-select.open .custom-select__input input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-select.open .custom-select__list {
  height: auto;
  max-height: 250px;
  overflow: auto;
  background-color: #1a1b1c;
  border: 2px solid #ffffff;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 0px 20px black;
}
.custom-select.open .custom-select__close {
  display: block;
}
.custom-select.open .custom-select__toggle img {
  transform: rotate(180deg);
}
.custom-select__toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 35px;
  padding: 10px;
}
.custom-select__toggle img {
  display: block;
  max-width: 100%;
  height: auto;
}
.custom-select__close {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.reports-wrap {
  display: flex;
  flex-wrap: wrap;
}
.reports-wrap__left {
  width: 100%;
  max-width: 250px;
}
.reports-wrap__right {
  width: 100%;
  max-width: calc(100% - 250px);
}

.reports-aside {
  background-color: #343a40;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.reports-aside__top {
  padding: 0.8125rem 0.5rem;
  border-bottom: 1px solid #4b545c;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.reports-aside__top-logo {
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  max-width: 35px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.reports-aside__top-text {
  font-weight: 300;
  font-size: 1.25rem;
  padding-top: 8px;
}
.reports-aside__menu {
  list-style: none;
  margin: 0;
  padding: 15px 0 0;
}
.reports-aside__menu-item-link, .reports-aside__menu-item-title {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #ffffff;
}
.reports-aside__menu-item-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  font-weight: 700;
}
.reports-aside__menu-item-title--icon {
  max-width: 26px;
  max-height: 26px;
  width: auto;
  height: auto;
  margin-right: 8px;
}
.reports-aside__menu-item-title--icon-wrap {
  width: 26px;
  height: 26px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.reports-aside__submenu {
  margin: 0;
  padding-left: 15px;
}
.reports-aside__submenu-item-link, .reports-aside__submenu-item-title {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #ffffff;
}

.reports-header {
  background-color: #333;
  min-height: 62px;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
}
.reports-header__user-name {
  font-size: 18px;
  font-weight: 700;
}
.reports-header__user-mail {
  font-size: 14px;
}
.reports-header__user-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.reports-header__user-option {
  margin-left: 15px;
  width: 26px;
}
.reports-header__user-option img {
  width: 100%;
  height: auto;
}

.main {
  padding: 15px;
}