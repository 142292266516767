.report {
    overflow: auto;
    .separator {
        &:last-child {
            display: none;
        }
    }
    tr {
        &:nth-child(2n) {
            background-color: rgba(255,255,255,0.05);
        }
        td {
            white-space: nowrap;
            font-size: 15px;
        }
        th {
            background-color: rgba(0,0,255,0.3);
        }
        .service-name {
            background-color: rgba(0,255,0,0.3);
        }
    }
    .tfoot {
        * {
            background-color: rgba(255,0,0,0.3);
        }
    }
    .filters-report {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: flex-end;
        .filter {
            display: none;
        }
        [name='filter'] {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            align-content: flex-end;
            & > div {
                display: none;
            }
            .filter--month__select-wrap,
            .submit {
                display: block;
            }
            .submit {
                margin: 0 15px 0 10px;
                button[type="submit"] {
                    font-size: 0;
                    &:after {
                        content: "Generuj raport";
                        font-size: 16px;
                        letter-spacing: .1em;
                    }
                }
            }
        }
    }
    .filter--month {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        margin-right: 20px;
        max-width: none;
        align-items: flex-end;
        align-content: flex-end;
        &__select {
            margin: 0;
            &-wrap {
                margin-right: 5px;
            }
        }
        &__btn-wrap {
            .btn {
                margin: 0 0 0 15px;
                font-size: 16px;
            }
        }
    }
    &-project {
        form[name="filter"] {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            align-content: flex-end;
        }
        &__header {
            margin-top: 0;
            margin-bottom: 10px;
            &-subprojects {
                margin-bottom: 10px;
            }
        }
        &__submit {
            &, button[type="submit"] {
                font-size: 16px;
                letter-spacing: .1em;
            }
        }
        &__date {
            margin-bottom: 30px;
        }
        &__select {
            margin-right: 15px;
        }
        &__table {

        }
    }
}

@media print {
    html, body {
        background: #ffffff !important;
        color: #000000 !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
        color-adjust: exact !important;                 /*Firefox*/
    }
    .report {
        overflow: visible !important;
    }
    .reports-wrap__left {
        width: 0 !important;
        display: none !important;
        * {
            display: none !important;
        }
    }
    .reports-header {
        width: 0 !important;
        display: none !important;
        * {
            display: none !important;
        }
    }
    .filters-report {
        display: none !important;
    }
    .reports-wrap__right {
        background-color: #ffffff !important;
        width: 100% !important;
        max-width: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 !important;
        table, tbody, tr, td, th {
            border-collapse: separate !important;
            border-color: #000000 !important;
        }
    }
    @page {
        margin: 5px !important;
    }
    .main {
        padding: 0 !important;
        margin: 0 !important;
    }
    .data__list--report {
        max-width: 100% !important;
    }
    table {
        page-break-inside: avoid;
        page-break-after: always;
        page-break-before: always;
    }
}
