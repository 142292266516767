.reports-header {
    background-color: #333;
    min-height: 62px;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    text-align: right;
    &__user {
        &-name {
            font-size: 18px;
            font-weight: 700;
        }
        &-mail {
            font-size: 14px;
        }
        &-options {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
        }
        &-option {
            margin-left: 15px;
            width: 26px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
