form[name="filter"] {
    max-width: 100%;
    margin-bottom: 30px;
    #filter {
        display: flex;
        flex-wrap: wrap;
        & > div {
            width: auto;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    select {
        margin: 0;
    }
    button[type="submit"] {
        margin-top: 23px;
    }
}
form[name="filter"] {
    .form-date {
        &__from, &__to {
            display: flex;
            select {
                width: auto;
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
