.list {
    a {
        color: inherit;
        text-decoration: none;
    }
    .freeDay {
        background-color: rgba(0,255,0,0.3);
    }
    .empty {
        background-color: rgba(255,0,0,0.3);
    }
    .today {
        background-color: rgba(0,0,255,0.3);
    }
}
.summary-filters {
    .service,
    .project,
    .type {
        display: none;
    }
}
.summary-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    align-items: flex-start;
    &__table {
        margin: 0 15px 30px;
        width: 100%;
        max-width: calc(20% - 30px);
    }
    &__time {
    }
}
