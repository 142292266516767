form {
    max-width: 350px;
    label {
        display: block;
        margin-bottom: 5px;
    }
    select, input {
        margin-bottom: 10px;
        height: 35px;
        font-size: 15px;
    }
    textarea {
        resize: none;
        width: 100%;
        min-height: 300px;
        max-width: 100%;
        border: 2px solid $color-font-main;
        border-radius: 5px;
        background-color: $color-bg-main;
        color: $color-font-main;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 15px;
        font-family: $font-main;
        &:focus, &:active {
            outline: none;
        }
    }
    input[type="text"], input[type="email"], input[type="password"] {
        background-color: $color-bg-main;
        color: $color-font-main;
        border: 2px solid $color-font-main;
        border-radius: 5px;
        padding: 0 5px;
        width: 100%;
        filter: grayscale(100%) brightness(110%);
        font-size: 15px;
    }
    select {
        width: 100%;
        background-color: $color-bg-main;
        color: $color-font-main;
        border: 2px solid $color-font-main;
        border-radius: 5px;
        &.roles {
            min-height: 75px;
        }
    }
    input[type="submit"], button[type="submit"] {
        height: 35px;
        padding: 0 30px;
        margin-left: auto;
        margin-right: 0;
        display: block;
        background-color: $color-font-main;
        color: $color-bg-main;
        border: 2px solid $color-font-main;
        border-radius: 5px;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.1em;
        cursor: pointer;
    }
    .form-date {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        select {
            max-width: calc((100% / 3) - 5px);
        }
    }
}
